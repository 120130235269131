<template>
  <div>
    <!-- Filters -->
    <the-search-filter
      i18nPrefix="users"
      :loading="state.loading"
      :searchFilter="state.searchFilter"
      @clear="clearSearchFilter()"
      @filterInput="param => updateInput(param)"
      @search="() => reloadUsers()"
    />

    <va-card>
      <!-- Title -->
      <va-card-title>
        {{ $t("users.title") }}
      </va-card-title>

      <!-- Table -->
      <va-card-content>
        <div class="va-table-responsive">
          <table class="va-table va-table--striped va-table--hoverable">
            <thead>
              <th>{{ $t('users.email') }}</th>
              <th>{{ $t('users.name') }}</th>
            </thead>
            <tbody>
              <tr v-for="(user, index) in state.users" :key="index">
                <td>
                  <router-link :to="{ path: userLink(user.id) }">
                    {{ user.email }}
                  </router-link>
                  <button-clipboard-copy :field="$t('users.email')" :value="user.email" />
                </td>
                <td>{{ user.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </va-card-content>
      <va-card-content class="load-more">
        <va-button v-if="canLoadMore()"
          @click="loadNextUsers()">
          {{ $t('users.loadMore') }}
        </va-button>
      </va-card-content>
    </va-card>
  </div>
</template>

<style scoped>

.va-table {
  width: 100%;
}

.load-more {
  text-align: center;
}
</style>

<script setup>
import { reactive,  onMounted, inject } from "vue";
import { callFetchUsers } from "@/api/identity";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import TheSearchFilter from "@/components/TheSearchFilter.vue";
import { userLink } from "@/utils/identity";

const toasts = inject("toasts");

const state = reactive({
  users: [],
  searchFilter: {
    email: { value: undefined, acceptedValues: "string" },
    search: { value: undefined, acceptedValues: "string", label : 'name' }
  },
  loading: false,
  nextPage: null,
  perPage: 20
});

/**
 * Clear search filter values
 */
function clearSearchFilter() {
  const searchFilterKeys = Object.keys(state.searchFilter);
  searchFilterKeys.forEach(key => {
    state.searchFilter[key].value = undefined;
  });

  reloadUsers();
}

/**
 * Update a searchFilter property's value
 * @param {Object} param
 * @param {string} param.inputKey
 * @param {any} param.value - depends on which field is updated
 */
function updateInput(param) {
  const paramKey = param.inputKey;
  const paramValue = param.value;

  state.searchFilter[paramKey].value = paramValue;
}

function resetUsers() {
  state.nextPage = null;
  state.users = [];
}

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} nextPage - Next page cursor
 */
async function loadUsers(page = null) {
  console.log('Loading users', { page, state });
  state.loading = true;

  const params = {
    count: state.perPage,
    page
  };

  Object.keys(state.searchFilter).forEach(key => {
    params[key] = state.searchFilter[key].value;
  });

  try {
    const resp = await callFetchUsers({ params });
    if (resp?.data) {
      console.log(resp.data);
      state.users = state.users.concat(resp.data.items.map(item => {
        return {
          id: item?.id,
          email: item?.email,
          name: item?.displayName,
        };
      }));

      state.nextPage = resp.data.nextPage;
    }
  } catch (err) {
    toasts?.error(err);
  }

  state.loading = false;
}

async function reloadUsers() {
  resetUsers();
  await loadUsers();
}

/**
 * Loads the next portion of users.
 */
async function loadNextUsers() {
  return await loadUsers(state.nextPage);
}

function canLoadMore() {
  return !!state.nextPage;
}

onMounted(async () => {
  await loadUsers();
});
</script>
